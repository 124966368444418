import React, { lazy, useEffect, useState } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import Roadmap from './pages/Roadmap';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login')) 
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))
const Tokenomics = lazy(() => import('./pages/Tokenomics')) 
/* const Roadmap = lazy(() => import('./pages/Roadmap'))  */


// Initializing different libraries
initializeApp()

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    // Call the checkAuth function to handle authentication
    checkAuth();
  }, []);


  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} /> 
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/tokenomics" element={<Tokenomics />} />
          <Route path="/roadmap" element={<Roadmap />} />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to="/login" replace />} />

        </Routes>
      </Router>
    </>
  )
}

export default App
