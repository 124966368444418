import React from 'react';

const Roadmap = () => {
  // Replace "your-image-url.jpg" with the actual URL of your image
  const imageUrl = "roadmap.jpg";

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Set a minimum height to cover the entire viewport
  };

  const gradientBackground = {
    background: 'linear-gradient(to bottom, #000000, #230B3A)',
    minHeight: '100vh', // Set a minimum height to cover the entire viewport
    overflow: 'hidden',
  };

  return (
    <div style={gradientBackground}>
      <div style={containerStyle}>
        <img
          src={imageUrl}
          alt="Roadmap"
          style={{ maxWidth: '100%', height: 'auto', display: 'block', margin: 'auto' }}
        />
      </div>
    </div>
  );
};

export default Roadmap;
