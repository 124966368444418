// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDQWeuFcAfIQ_2Y8v4F7K4A8XN5QtLXzic",
  authDomain: "aixrp-1185b.firebaseapp.com",
  projectId: "aixrp-1185b",
  storageBucket: "aixrp-1185b.appspot.com",
  messagingSenderId: "1038663437041",
  appId: "1:1038663437041:web:3503ddbce0259bb8987750",
  measurementId: "G-WC7C1S0HHT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);