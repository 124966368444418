import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase"; // Import the Firebase auth instance

const checkAuth = () => {
  // Add a callback function to handle the user's authentication state
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in through Firebase Authentication
      // Set the user's authentication information, e.g., user.uid
      const TOKEN = user.uid;

      axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

      // Add interceptors and other axios configurations as needed
      axios.interceptors.request.use(function (config) {
        // Add code to show global loading indicator
        document.body.classList.add("loading-indicator");
        return config;
      }, function (error) {
        return Promise.reject(error);
      });

      axios.interceptors.response.use(function (response) {
        // Add code to hide global loading indicator
        document.body.classList.remove("loading-indicator");
        return response;
      }, function (error) {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      });
    } else {
      // User is not signed in, you can handle this case as needed
      // For example, you can redirect the user to the login page
      /* window.location.href = '/login'; */
    }
  });
};

export default checkAuth;